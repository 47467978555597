module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"moigonz","short_name":"moigonz","start_url":"/","background_color":"#CC2800","theme_color":"#CC2800","display":"minimal-ui","icon":"src/images/moigonz-logo-tiny.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"25ff4d3f56f0cd6d83b10cc10f708d54"},
    },{
      plugin: require('../node_modules/gatsby-plugin-netlify-cms/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
